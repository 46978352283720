const initialState = {
    examLaunched: {},
    isAssessmentReview:false,
    isPracAssessmentLaunched:false,
    isPracAssessmentSaved:false,
}

const examLaunched = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_IS_EXAM_LAUNCHED':
            state.examLaunched = action.payload
            return {...state};
        case 'SET_IS_ASSESSMENT_REVIEW':
            state.isAssessmentReview = action.payload
            return {...state};
        case 'SET_PRAC_EXAM_LAUNCHED':
            state.isPracAssessmentLaunched = action.payload
            return {...state};
        case 'SET_PRAC_EXAM_SAVED':
            state.isPracAssessmentSaved = action.payload
            return {...state};
        default:
            return state;
    }
}

export default examLaunched;