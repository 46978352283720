import React from "react";

const AssessmentMethods = (props) => {
    switch (props.isStudentAssest) {
      case 1:
        return (
          <>
          <div className="assessment-method">
            <p className="as-method"><i class="fal fa-user"></i>&nbsp;Self Assessed</p>
          </div>
          </>
        );
        break;
      case 0:
        return (
          <>
            <div className="assessment-method">
              <p className="as-method"><i class="fal fa-user"></i>&nbsp;Lecturer Assessed</p>
            </div>
          </>
        );
        break;
      default:
        return
    }
}
export default AssessmentMethods;
