import React, { useState, useEffect, useRef, useCallback, memo } from "react";
import VideoPlayer from "./VidoePlayer";
import QuizeImage from "../../assets/images/ic-1.png";
import {
  getAssembliesItem,
  getAssembliesItemAdmin,
  fileTrackingService,
  } from "../../services/AssembliesService";
import SideSubAssembliesItems from "./SideSubAssembliesItems";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { UpdateLessonType, updateCurrentActivity, updateCurrentAssembly } from "../../store/actions";
import { TrimText } from "../common/TrimText";
import { formatDuration } from "../../utils/commonFunction";

let obj = {};

function SideAssemliesItems(props) {
  
  const {parentAccId,lessonData} = props;
 
  const [video_duration_time, setVideo_duration_time]=useState("")
  // const [mounted, setMounted] = useState(false);
  const dispatch= useDispatch()
  // const [buttonCheckedStatus1, setButtonCheckedStatus1] = useState(0);
  // const [documentId1, setDocumentId1] = useState([]);
  // const [currentVideoId1, setCurrentVideoId1] = useState(0);
  const [showDescriptions, setShowDescriptions] = useState({});
  const [expandedModuleId, setExpandedModuleId] = useState(null);
  const [showDescriptionState, setShowDescriptonState]=useState(false)
// Function to toggle description visibility for a specific item

const toggleDescription = (itemId) => {
  setShowDescriptonState(!showDescriptionState)
  setShowDescriptions((prevState) => ({
    ...prevState,
    [itemId]: !prevState[itemId],
  }));
};

  // const currentAssembly = useSelector(state => state.learningMaterial.currentAssembly)
  const currentActivity=useSelector(state=>state.learningMaterial.current_activity)
  // const lesson_type = useSelector(state => state.learningMaterial.active_lesson_type);
  // const setCustomeCurrentVideoId = (videoId) => {
  //   setCurrentVideoId1(videoId);
  // };

  // const handleFileTracking = (itemId) => {  
  //   fileTrackingService(itemId).then((res) => {
  //     setButtonCheckedStatus1(true);
  //     setDocumentId1([...documentId1, itemId]);
  //     props.parentGetLearningMaterialData(props.parentSubId);
  //     props.refreshProgress(props.lesson.module_id);
  //   });
  //   getSubContentMethod();
  // };

  
  // const formatDuration = (duration) => {
  //   const parts = duration.match(/(\d+)([mhms])/g);
  //   let seconds = 0;
  
  //   parts.forEach(part => {
  //     const value = parseInt(part.match(/\d+/)[0], 10);
  //     const unit = part.match(/[mhms]/)[0];
  
  //     switch (unit) {
  //       case 'h':
  //         seconds += value * 3600;
  //         break;
  //       case 'm':
  //         seconds += value * 60;
  //         break;
  //       case 's':
  //         seconds += value;
  //         break;
  //       default:
  //         break;
  //     }
  //   });
  
  //   return moment.utc(seconds * 1000).format('mm:ss');
  // };

  // useEffect(() => {
  //   if(assemblyItem?.video_duration){
  //   const formattedTime = formatDuration(assembliesItems?.video_duration);
  //   setVideo_duration_time(formattedTime)}
  // }, [assemblyItem?.video_duration])

  const handleOpenLesson = (item) => {
    dispatch(updateCurrentActivity(item?.item_id));
    dispatch(UpdateLessonType(item?.item_type));
  };


  const HandleSubAccOpen = (item,e) => {
    e.preventDefault()
    // props.setLessonList(item?.sub_children);
    dispatch(updateCurrentActivity(item?.sub_children[0]?.item_id));
    dispatch(UpdateLessonType(item?.sub_children[0]?.item_type));
  };

  // useEffect(() => {
  //   if (currentActivity && lesson_type === 3) {
  //     let element = document.getElementById(currentActivity);
  //     let allEle = document.querySelectorAll(".learing-sub-assembly");
  //     let allParent = document.querySelectorAll(".sub-assembly-item");
  //     let childEle = document.querySelector(`#accordionBodyOne${currentActivity}`);
      
  //     if (element && childEle && allEle.length > 0 && allParent.length > 0) {
  //       allEle.forEach(ele => {
  //         ele.classList.remove("show");
  //       });
  //       allParent.forEach(ele => {
  //         ele.setAttribute("aria-expanded", false);
  //       });
  //       element.setAttribute("aria-expanded", true);
  //       childEle.classList.add("show");
  //     }
  //   }
  // }, [currentActivity, lesson_type]);
   
  const handleAccordionToggle = (itemId) => {
    if (expandedModuleId === itemId) {
      setExpandedModuleId(null);
    } else {
      setExpandedModuleId(itemId);
    }
  };

  return ( 
    <>
       <li key={lessonData?.item_id} className="lesson">
        <div className={"sub-body" + (lessonData?.item_type === 2 ? ' video-sec' : '')}>
          {lessonData?.item_type === 2 ? (
            <div
              className={`lesson-card ${lessonData?.item_id == currentActivity ? "lesson-active" : ""}`}
              onClick={(e) => handleOpenLesson(lessonData,e)}
              title={lessonData?.item_name.includes(": ") ? lessonData?.item_name.split(": ")[1] : lessonData?.item_name}>   
              <div className="card-media-text d-flex">
                <div className={`checkbox-div ${lessonData?.watched_history?.watch_percentage == 1 ? 'checked' : ''}`}>
                  {lessonData?.watched_history?.watch_percentage == 1 && (
                    <i className="fal fa-check checkbox-icon"></i>
                  )}
                </div>
                <div className="">
                  <p className="lesson__name m-0" title={lessonData?.item_name.includes(": ") ? lessonData?.item_name.split(": ")[1] : lessonData?.item_name}>{TrimText(lessonData?.item_name.includes(": ") ? lessonData?.item_name.split(": ")[1] : lessonData?.item_name, 28)}</p>
                  {lessonData?.video_duration ? <p className="document-detail"><span className="document-size" title={formatDuration(lessonData?.video_duration)}>{formatDuration(lessonData?.video_duration )}<span className="document-document-icon" title="play"><i className="fal fa-play"></i></span></span></p>:null}
                </div>
              </div>
              <p className="assets lesson__file__types__icon" title="video">
              <i title="Video" className="fal fa-video"></i>
              </p>
            </div>
          ) : null}


          {lessonData?.item_type == 7 ? (
            <div className={`text-lesson lesson-card ${lessonData?.item_id == currentActivity ? "lesson-active" : ""}`}
            onClick={(e) => handleOpenLesson(lessonData)}
            title={lessonData?.item_name.includes(": ") ? lessonData?.item_name.split(": ")[1] : lessonData?.item_name}>
              <div className="card-media-text w-100">
                <div className="d-flex justify-content-between align-items-center text-lebel">
                  <p className="label-assembly" title={lessonData?.item_name.includes(": ") ? lessonData?.item_name.split(": ")[1] : lessonData?.item_name}>{TrimText(lessonData?.item_name.includes(": ") ? lessonData?.item_name.split(": ")[1] : lessonData?.item_name, 28)}</p>
                  <i title="Lebel" className="fal fa-tag" />
                </div>
                {lessonData?.label_show_description == 1 ? <div className="w-100">
                  <p className="label-desc" title={lessonData?.item_desc}>
                    {!showDescriptions[lessonData?.item_id] ? (
                      TrimText(lessonData?.item_desc, 45)
                    ) : (
                      lessonData?.item_desc
                    )}
                  </p>
                  {lessonData?.item_desc?.length > 45 && <p className="assets">
                    <span
                      title={showDescriptionState ? "See Less" : "See More"}
                      className={`show__label__text`}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDescription(lessonData?.item_id)
                      }}
                    >{showDescriptionState ? "See Less" : "See More"}</span>
                  </p>}
                </div> : null}
              </div>
            </div>
          ) : null}

          {lessonData?.item_type == 4 ? (
           <div className={`lesson-card ${lessonData?.item_id == currentActivity ? "lesson-active" : ""}`}
           onClick={(e) => handleOpenLesson(lessonData)}
           title={lessonData?.item_name.includes(": ") ? lessonData?.item_name.split(": ")[1] : lessonData?.item_name}>   
              <div className="card-media-text d-flex" >
                <div className={`checkbox-div ${lessonData?.buttonCheckedStatus ? 'checked' : ''}`}>
                  {lessonData?.buttonCheckedStatus && (
                    <i className="fal fa-check checkbox-icon"></i>
                  )}
                </div>                
                <div>
                  <p className="lesson__name">{TrimText(lessonData?.item_name.includes(": ") ? lessonData?.item_name.split(": ")[1] : lessonData?.item_name,28)}</p>
                  {lessonData?.document_size ? <p className="document-detail"><span className="document-size" title={lessonData?.document_size || "NA"}>{lessonData?.document_size ? `${lessonData?.document_size}`  : "NA"}</span><span className="document-document-icon"><i className="fal fa-download"></i></span></p>: null}
                </div>
              </div>
              <div className="assets lesson__file__types__icon">
                {(() => {
                  const documentExt = lessonData?.documentName?.split(".").pop();
                  const uploadLinkExt = lessonData?.upload_link?.split(".").pop();
                  const fileExt = documentExt || uploadLinkExt;
                  return fileExt ? (
                    {
                      // Document icons
                      doc: <i title="Document" className="fal fa-file-word"></i>,
                      docx: <i title="Document" className="fal fa-file-word"></i>,
                      pdf: <i title="Document" className="fal fa-file-pdf"></i>,
                      ppt: <i title="Document" className="fal fa-file-powerpoint"></i>,
                      xlsx: <i title="Document" className="fal fa-file-excel"></i>,
                      zip: <i title="Document" className="fal fa-file-archive"></i>,

                      // Image icons (with more formats)
                      jpg: <i title="Image" className="fal fa-image"></i>,
                      jpeg: <i title="Image" className="fal fa-image"></i>,
                      png: <i title="Image" className="fal fa-image"></i>,
                      gif: <i title="Image" className="fal fa-image"></i>,
                      svg: <i title="Image" className="fal fa-image"></i>,
                      bmp: <i title="Image" className="fal fa-image"></i>,
                      tiff: <i title="Image" className="fal fa-image"></i>,
                      webp: <i title="Image" className="fal fa-image"></i>,
                      ico: <i title="Image" className="fal fa-image"></i>,
                      jfif: <i title="Image" className="fal fa-image"></i>,  // Added JFIF support

                      // Add more formats if necessary
                    }[fileExt] || (
                      <>
                        {/* Default generic file icon */}
                        <i title="File" className="fal fa-file"></i>
                      </>
                    )
                  ) : null;
                })()}
              </div>

            </div>
          ) : null}

          {lessonData?.item_type == 8 ? (
            <div className={`lesson-card ${lessonData?.item_id == currentActivity ? "lesson-active" : ""}`}
            onClick={(e) => handleOpenLesson(lessonData,e)}
            title={lessonData?.item_name}>
              <div className="card-media-text d-flex align-items-center" >
                <div
                  className={`checkbox-div ${lessonData?.buttonCheckedStatus ? 'checked' : ''}`}
                >
                  {lessonData?.buttonCheckedStatus && (
                    <i className="fal fa-check checkbox-icon"></i>
                  )}
                </div>
                <div>
                  <p className="lesson__name m-0">{TrimText(lessonData?.item_name.includes(": ") ? lessonData?.item_name.split(": ")[1] : lessonData?.item_name, 28)}</p>
                  {/* <p className="document-detail"><span className="document-size">{lessonData?.document_size ? `${lessonData?.document_size}` : "NA"}</span><span className="document-document-icon"><i className="fal fa-download"></i></span></p> */}
                </div>
              </div>
              <div className="assets lesson__file__types__icon">
                <i title="File" className="fal fa-info-circle"></i>
              </div>

            </div>
          ) : null}

          {lessonData?.item_type === 3 && (
            <div className={`accordion parent-accordion ${lessonData?.sub_children?.length < 1 ? 'disabled' : null}`} id={`accordionParent${lessonData?.assembly_id}`} onClick={(e)=>HandleSubAccOpen(lessonData,e)} title={lessonData?.item_name}>
              {console.log("lessonData?.sub_children?.length",lessonData?.sub_children?.length)}
              <div className="card additional-card-class">
                <div
                  className="card-header additional-header-class"
                  onClick={() => handleAccordionToggle(lessonData?.item_id)}
                  id={`heading${lessonData?.item_id}`}
                  data-toggle="collapse"
                  data-target={`#collapse${lessonData?.item_id}`}
                  aria-expanded="false"
                  aria-controls={`collapse${lessonData?.item_id}`}
                >
                  <div className="lesson__heading__div flex-row justify-content-between">
                    <h3 className="lesson__heading__title" title={lessonData?.item_name.includes(": ") ? lessonData?.item_name.split(": ")[1] : lessonData?.item_name}>{TrimText(lessonData?.item_name.includes(": ") ? lessonData?.item_name.split(": ")[1] : lessonData?.item_name, 28)}</h3>
                    <div className="header-status">
                      <div className="status-gol">
                      <i className={`fal ${(expandedModuleId === lessonData?.item_id && lessonData?.sub_children?.some(sub => sub.item_id == currentActivity)) ? "fa-chevron-up" : "fa-chevron-down"}`}></i>
                      </div>
                    </div>
                  </div>
                  {lessonData?.item_desc ? <p className="category__desc__text" title={lessonData?.item_desc}>{TrimText(lessonData?.item_desc,75)}</p> : null}
                  <li className="material-info deep-text">
                    {[
                      { title: "Videos", icon: "fa-video", data: lessonData?.__meta__?.sub_children_video_count },
                      { title: "Documents", icon: "fa-file", data: lessonData?.__meta__?.sub_children_document_count },
                      { title: "Quiz", icon: "fa-clipboard-list", data: lessonData?.__meta__?.sub_children_quiz_count },
                      { title: "Link", icon: "fa-external-link", data: lessonData?.__meta__?.sub_children_link_count },
                    ].map(({ title, icon, data }, index) =>
                      data > 0 && (
                        <React.Fragment key={index}>
                          <i title={title} className={`fal ${icon}`} />
                          {data}
                        </React.Fragment>
                      )
                    )}
                  </li>

                  {lessonData?.sub_children?.length > 0 ? <div className="course-item-progress">
                    <div className="progress" style={{ height: "11px" }} title="progress">
                      <div
                        className="progress-bar progress-bar-text"
                        role="progressbar"
                        style={{ width: lessonData?.percentage + "%" }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {lessonData?.percentage || "0"}%
                      </div>
                    </div>
                  </div> : null}
                </div>

                {/* Nested Collapse for Sub-Items */}
                <div
                  id={`collapse${lessonData?.item_id}`}
                  className={`collapse additional-collapse-class ${lessonData?.sub_children?.some(sub => sub.item_id == currentActivity) ? "show" : null}`}
                  aria-labelledby={`heading${lessonData?.item_id}`}
                  data-parent={parentAccId}
                 
                >
                  <div className="sub-body">
                    <ul className="list-group additional-list-group-class">
                      {console.log("lessonData?.sub_children",lessonData?.sub_children)}
                      {lessonData?.sub_children?.map((lessonData1, index1) => (
                        <SideSubAssembliesItems
                          key={index1}
                          lessonData={lessonData1}
                          currentActivity={currentActivity}
                        />
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}


          {lessonData?.item_type === 5 ? (
            <div className={`lesson-card ${lessonData?.item_id == currentActivity ? "lesson-active" : ""}`}
            onClick={(e) => handleOpenLesson(lessonData,e)}
            title={lessonData?.item_name.includes(": ") ? lessonData?.item_name.split(": ")[1] : lessonData?.item_name}>  
                <div className="card-media-text d-flex">
                <div className={`checkbox-div ${lessonData?.buttonCheckedStatus ? 'checked' : ''}`}>
                  {lessonData?.buttonCheckedStatus && (
                    <i className="fal fa-check checkbox-icon"></i>
                  )}
                </div>
                <div className="">
                  <p className="lesson__name">{ TrimText(lessonData?.item_name.includes(": ") ? lessonData?.item_name.split(": ")[1] : lessonData?.item_name,28)}</p>
                </div>
              </div>
              <div className="assets lesson__file__types__icon" title="Link">
              <i title="Link" className="fal fa-external-link"></i>
              </div>
            </div>
          ) : null}

          {lessonData?.item_type === 6 ? (
            <div className={`lesson-card ${lessonData?.item_id == currentActivity ? "lesson-active" : ""}`}
            onClick={(e) => handleOpenLesson(lessonData,e)}
            title={lessonData?.item_name.includes(": ") ? lessonData?.item_name.split(": ")[1] : lessonData?.item_name}>   
              <div className="card-media-text d-flex" >
                <div className={`checkbox-div ${lessonData?.buttonCheckedStatus ? 'checked' : ''}`}>
                  {lessonData?.buttonCheckedStatus && (
                    <i className="fal fa-check checkbox-icon"></i>
                  )}
                </div>
                <div className="">
                  <p className="lesson__name" title={lessonData?.item_name.includes(": ") ? lessonData?.item_name.split(": ")[1] : lessonData?.item_name}>{ TrimText(lessonData?.item_name.includes(": ") ? lessonData?.item_name.split(": ")[1] : lessonData?.item_name,28)}</p>
                </div>
              </div>
              <div className="assets lesson__file__types__icon">
               <i title="Quiz" class="fal fa-clipboard-list ml-2"></i>
              </div>
            </div>
          ) : null}
        </div>
      </li>
    </>
  );
}

export default memo(SideAssemliesItems)