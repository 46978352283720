import React, { useEffect, useState } from 'react'
import DisplayQuestion from './DisplayQuestion'
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import Str from '../../common/Str';
import ReviewQuiz from './ReviewQuiz_New';

const PreviewQuestionsExam = (props) => {
  const { quiz } = useSelector((state) => state.quiz);
  const quizData = Object.values(quiz);
  const keys = Object.keys(quiz);
  const [questionData, setquestionData] = useState({});
  const [refresh, setrefresh] = useState(false);
  const [clear, setclear] = useState(false);
  const [quesno, setquesno] = useState(1);
  const [question, setquestion] = useState(0);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [assessementDetails, setAssessmentDetails]=useState([])
  const [assessmentType, setAssessmentType]=useState('')
  const user = useSelector((state) => state.user);
  const [photoFiles, setPhotoFiles] = useState({});
  const [videoFiles, setVideoFiles] = useState({});
  const [docFiles, setDocFiles] =useState({})
  const [lecturerObservations, setLecturerObservations] = useState({questionId:'', value:'', updated:false});
  const [assessementQuestionList, setAssessementQuestionList]=useState([])
  const [files, setFiles] = useState([]);

  useEffect(()=>{
    if (props.trigger) {
      handleSubmit('');
    }
  },[props.trigger])

  useEffect(() => {
    setclear(!clear);
    if (quizData.length) {
      if (question === 0) {
        if (Array.isArray(quizData[0])) {
          setquestion(quizData[0][0].qid);
        } else {
          setquestion(quizData[0].qid);
        }
        setquesno(1);
        setquestionData(quizData[0]);
      }
    }
  }, [quiz]);

  const renderQuesType = (type) => {
    switch (type) {
      case "Multiple Choice Single Answer":
        return <span className="ques__type">Single Choice <i className="fal fa-tasks"></i></span>;
      case "Multiple Choice Multiple Answer":
        return <span className="ques__type">Multiple Choice <i className="far fa-check-square"></i></span>;
      case "Match the Column":
        return <span className="ques__type">Match The Column <i className="far fa-line-columns"></i></span>;
      case "Short Answer":
        return <span className="ques__type">Short Answer <i className="far fa-comment-alt-lines"></i></span>;
      case "Long Answer":
        return <span className="ques__type">Long Answer <i className="fal fa-align-left"></i></span>;
      case "Fill in the blank":
        return <span className="ques__type">Fill In The Blanks <i className="far fa-keyboard"></i></span>;
      case "Order List":
        return <span className="ques__type">Order List <i className="fas fa-sort-numeric-down"></i></span>;
      case "Choice Matrix":
        return <span className="ques__type">Choice Matrix <i className="fal fa-table"></i></span>;
      case "Document Upload":
        return <span className="ques__type">Document Upload <i className="fal fa-file-upload"></i></span>;
      case "Practical Criteria":
        return <span className="ques__type">Practical Criteria <i className="fal fa-ballot-check"></i></span>;
      default:
        return
    }
  }

  const handleQuestion = (id) => {
    const fData = quizData.filter((item) => Array.isArray(item) ? item[0].qid == id : item.qid == id);
    setquestionData(fData[0]);
  };

  const handleNav = (nextNav) => {
    if (nextNav == 'next' && quesno != (quizData.length)) {
      setquesno(quesno + 1);
      localStorage.setItem(
        Array.isArray(quizData[quesno]) ? `@qa${quizData[quesno][0].qid}qa` : `@qa${quizData[quesno].qid}qa`,
        parseInt(localStorage.getItem(Array.isArray(quizData[quesno]) ? `@qa${quizData[quesno][0].qid}qa` : `@qa${quizData[quesno].qid}qa`) || 0) + 1
      );
      setquestion(Array.isArray(quizData[quesno]) ? quizData[quesno][0].qid : quizData[quesno].qid);
      handleQuestion(Array.isArray(quizData[quesno]) ? quizData[quesno][0].qid : quizData[quesno].qid);
      // handleSaveAndNext();
    }
    else if (nextNav == 'back' && quesno != 1) {
      // console.log('the ques and quizData is ', quesno, quizData, quizData.length)
      setquesno(quesno - 1)
      localStorage.setItem(
        Array.isArray(quizData[quesno - 2]) ? `@qa${quizData[quesno - 2][0].qid}qa` : `@qa${quizData[quesno - 2].qid}qa`,
        parseInt(localStorage.getItem(Array.isArray(quizData[quesno - 2]) ? `@qa${quizData[quesno - 2][0].qid}qa` : `@qa${quizData[quesno - 2].qid}qa`) || 0) + 1
      );
      setquestion(Array.isArray(quizData[quesno - 2]) ? quizData[quesno - 2][0].qid : quizData[quesno - 2].qid);
      handleQuestion(Array.isArray(quizData[quesno - 2]) ? quizData[quesno - 2][0].qid : quizData[quesno - 2].qid);
      // handleSaveAndNext();
    }
    else if (nextNav == 'last' && quesno != (quizData.length)) {
      setquesno(quizData.length)
      localStorage.setItem(
        Array.isArray(quizData[quizData.length - 1]) ? `@qa${quizData[quizData.length - 1][0].qid}qa` : `@qa${quizData[quizData.length - 1].qid}qa`,
        parseInt(localStorage.getItem(Array.isArray(quizData[quizData.length - 1]) ? `@qa${quizData[quizData.length - 1][0].qid}qa` : `@qa${quizData[quizData.length - 1].qid}qa`) || 0) + 1
      );
      setquestion(Array.isArray(quizData[quizData.length - 1]) ? quizData[quizData.length - 1][0].qid : quizData[quizData.length - 1].qid);
      handleQuestion(Array.isArray(quizData[quizData.length - 1]) ? quizData[quizData.length - 1][0].qid : quizData[quizData.length - 1].qid);
      // handleSaveAndNext();
    }
    else if (nextNav == 'first' && quesno != 1) {
      setquesno(1)
      localStorage.setItem(
        Array.isArray(quizData[0]) ? `@qa${quizData[0][0].qid}qa` : `@qa${quizData[0].qid}qa`,
        parseInt(localStorage.getItem(Array.isArray(quizData[0]) ? `@qa${quizData[0][0].qid}qa` : `@qa${quizData[0].qid}qa`) || 0) + 1
      );
      setquestion(Array.isArray(quizData[0]) ? quizData[0][0].qid : quizData[0].qid);
      handleQuestion(Array.isArray(quizData[0]) ? quizData[0][0].qid : quizData[0].qid);
      // handleSaveAndNext();
    }
  }

  function inRange(x, min, max) {
    return ((x - min) * (x - max) <= 0);
  }

  const isShowing = (index) => {
    if (quizData.length <= 10) {
      return ''
    }
    else if (quesno > 4 && quesno < quizData.length - 5) {
      if (inRange(index, quesno - 4, quesno + 5)) {
        return ''
      }
      else {
        return 'd-none'
      }
    }
    else if (quesno <= 4) {
      if (inRange(index, 0, 9)) {
        return
      }
      else {
        return 'd-none'
      }
    }
    else if (quesno >= quizData.length - 5) {
      if (inRange(index, quizData.length - 10, quizData.length)) {
        return
      }
      else {
        return 'd-none'
      }
    }
  }

  const getSubmittedAnswer = (options, selected, item) => {
    if (item.question_type === "Multiple Choice Single Answer") {
      return options.find(elem_item => elem_item.oid == selected)?.q_option
    }
    if (item.question_type === "Multiple Choice Multiple Answer") {
      return options.filter(elem_item => selected.includes(elem_item.oid.toString())).map(elem => (elem.q_option)).join("___")
    }
    if (item.question_type === "Match the Column") {
      return item.choice.join(",")
    }
    if (item.question_type === "Short Answer") {
      return selected;
    }
    if (item.question_type === "Long Answer") {
      return selected;
    }
    if (item.question_type === "Order List") {
      return item.selectedItems?.join("____")
    }
    if (item.question_type === "Fill in the blank") {
      return selected.map(elem_item => elem_item.q_option_match).join("____")
    }
    if (item.question_type === "Document Upload") {
      return item.docs;
    }
    if (item.question_type === "Choice Matrix") {
      return selected.map((item)=>{
        let final_val = item.q_option.map(opt=>(opt.titleValue)).join(",")
        final_val = final_val + "___" + item.q_option_match.titleValue;
        return final_val;
      }).join(",");
    }
  }

  const getCorrectAnswer = (options, type) =>{    
    if (type === "Multiple Choice Multiple Answer" || type === "Multiple Choice Single Answer") {
      return options.filter(item => item.score>0).map(elem=>elem.q_option).join("___");
    }
    if (type === "Match the Column") {
      return options.map(item =>(item.q_option + "___" + item.q_option_match)).join(",")
    } 
    if (type === "Short Answer" || type === "Order List" || type === "Fill in the blank") {
      return options.map(item => item.q_option).join("___")
    }
    if (type === "Long Answer") {
      return options.map(item => item.q_option).join("___")
    }
    if (type === "Choice Matrix") {
      return options.map(item => (JSON.parse(item.q_option).filter(elem => elem.isSelected==true).map(opt => opt.titleValue).join(",") + "___" + item.titleValue)).join(",");
    }

  }

  const handleSubmit = async (section = '') => {
    // settime(-2);
    setLoading(true);
    setSubmitLoading(true);
    var score_individual = [];
    var total_score_individual = [];
    var individual_time = [];
    var individual_time_db = [];
    var quizanswer = [];
    var qid = [];

    for (let i = 0; i < localStorage.length; i++) {
      let value = localStorage.key(i);
      if (value.startsWith("@qa")) {
        // console.log(value);
        // const key = value.substr(1);
        // console.log("________ value", value);
        const key = value.replace("@qa","").replace("qa","");
        // console.log("________ key", key);
        const score = quizData.filter((data) => Array.isArray(data) ? data.filter(d=> d.qid == key) : data.qid == key);
        let scoreTemp = 0;
        if (score.length > 0) {
          scoreTemp = score[0].score;
        }
        individual_time.push({
          key,
          time: localStorage.getItem(value),
          score: scoreTemp,
        });
      }
    }


    const getAnswerItem = (item) => {
      // console.log("item.question_type>>>>>>>>>>>>", item.question_type)
      const answerItemList = []
      if (item.question_type == "Multiple Choice Single Answer") {
        if (
          item.selected != "" &&
          item.selected != [] &&
          item.selected != null
        ) {
          answerItemList.push({
            aid: 0,
            qid: item.qid,
            q_option: item.selected,
            score: item.score,
            docs: null,
          });
        }
      }

      // Multiple Choice Multiple Answer
      if (item.question_type == "Multiple Choice Multiple Answer") {
        // console.log("the code is above here")
        item && item?.selected && item?.selected?.length && item.selected.map((data) => {
          // console.log("the code is here")
          if (
            item.selected != "" &&
            item.selected != [] &&
            item.selected != null
          ) {
            if (data != "" && data != null) {
              answerItemList.push({
                aid: 0,
                qid: item.qid,
                q_option: data,
                score: item.score,
                docs: null,
              });
            }
          }
        });
      }

      // Match the Column
      if (item.question_type == "Match the Column") {
        // console.log("itemssssss->",item);
        item.choice.map((data, itr) => {
          if (data != "" && data != [] && data != null) {
            answerItemList.push({
              aid: 0,
              qid: item.qid,
              q_option: data,
              score: item.scoreArr[itr],
              docs: null,
            });
          }
        });
      }

      // Short Answer
      if (item.question_type == "Short Answer") {
        if (
          item.selected != "" &&
          item.selected != [] &&
          item.selected != null
        ) {
          answerItemList.push({
            aid: 0,
            qid: item.qid,
            q_option: item.selected.toString(),
            score: item.score,
            docs: null,
          });
        }
      }

      // Long Answer
      if (item.question_type == "Long Answer") {
        if (
          item.selected != "" &&
          item.selected != [] &&
          item.selected != null
        ) {
          answerItemList.push({
            aid: 0,
            qid: item.qid,
            q_option: item.selected.toString(),
            score: item.score,
            docs: null,
          });
        }
      }

      // order list
      if (item.question_type == "Order List") {
        // console.log("itemssssss->",item);
        item.choice.map((data, ind) => {
          if (data !== "" && data.length && data !== null) {
            answerItemList.push({
              aid: 0,
              qid: item.qid,
              q_option: data,
              score: item.scoreArr[ind],
              docs: null,
            });
          }
        })
      }

      // Fill In The Blank
      if (item.question_type == "Fill in the blank") {
        // console.log("itemssssss->",item);
        item.selected.map((data) => {
          if (data != "" && data != [] && data != null) {
            answerItemList.push({
              aid: 0,
              qid: item.qid,
              q_option: `${data.q_option}___${JSON.stringify([data.q_option_match.trim()])}`,
              score: data.score,
              docs: null,
            });
          }
        })
      }

      // Choice Matrix
      if (item.question_type == "Choice Matrix") {
        // console.log("itemssssss->",item);
        item.selected.map((data) => {
          if (data != "" && data != [] && data != null) {
            answerItemList.push({
              aid: 0,
              qid: item.qid,
              q_option: `${JSON.stringify(data.q_option)}___${JSON.stringify([data.q_option_match])}`,
              score: data.score,
              docs: null,
            });
          }
        })
      }

      // Document Upload
      if (item.question_type === "Document Upload") {
        // console.log("document upload item->",item);
        if (item?.docs) {
          item.docs.map((d) => {
            answerItemList.push({
              aid: 0,
              qid: item.qid,
              docs: d,
            });
          })
        }
      }
      return answerItemList;
    }

    quizData.map((item) => {
      // total_score_individual.push(item.correct_score)
      // Multiple Choice Single Answer
      if (item.question_type) {
        quizanswer = [...quizanswer, ...getAnswerItem(item)]
        // quizanswer.push(...getAnswerItem(item))
        qid.push(item.qid);
      } else if (Array.isArray(item) && item.length) {
        item.map((subItem) => {
          // total_score_individual.push(subItem.correct_score)
          // quizanswer.push(...getAnswerItem(subItem));
          quizanswer = [...quizanswer, ...getAnswerItem(subItem)]
          qid.push(subItem.qid);
        })
      }
    });

    individual_time.sort(function (a, b) {
      return a.key - b.key;
    });

    individual_time.map((item) => {
      // qid.push(item.key);
      // score_individual.push(item.score);
      individual_time_db.push(item.time);
    });

    qid.map(q => {
      quizData.map(qd => {
        if (Number(q) === qd?.qid) {
          total_score_individual.push(qd.correct_score)
          score_individual.push(qd?.score || 0)
        } else if (Array.isArray(qd)) {
          qd.map(subQd => {
            if (Number(q) === subQd.qid) {
              total_score_individual.push(subQd.correct_score)
              score_individual.push(subQd?.score || 0)
            }
          })
        }
      })
    })

    // console.log("______qid",qid)
    // console.log("______score_individual",score_individual)
    // console.log("______total_score_individual",total_score_individual)
    // console.log("_______________individual_time",individual_time);
    let reviewResult = []
    var count = 0;
    const finalData = quizData.map((item, index) => {
      if (Array.isArray(item) && item.length) {
        const itemArray =  item.map((subItem, ind) => {
          count+=1;
          return ({
            question_name: subItem.question_name,
            question: subItem.question,
            question_type: subItem.question_type,
            level_name: subItem.level_name,
            UserAnswer : getSubmittedAnswer(subItem.options, subItem.selected, subItem),
            correctAnswer : getCorrectAnswer(subItem.options, subItem.question_type),
            result : subItem?.score || 0,
            score : subItem?.score || 0,
            totalScoreIndividual : subItem?.correct_score,
            time : individual_time[count-1].time,
            ques: subItem,
          })
        })
        reviewResult = [...reviewResult, ...itemArray]
      } else {
        const submitted_ans = getSubmittedAnswer(item.options, item.selected, item)        
        const singleItem = {
          question_name: item.question_name,
          question: item.question,
          question_type: item.question_type,
          level_name: item.level_name,
          UserAnswer : submitted_ans,
          correctAnswer : getCorrectAnswer(item.options, item.question_type),
          result : individual_time.find(val => val.key == item.qid)?.score || item?.score || 0,
          score : individual_time.find(val => val.key == item.qid)?.score || item?.score || 0,
          totalScoreIndividual : item?.correct_score,
          time : individual_time[count].time,
          ques: item,
        }
        reviewResult = [...reviewResult, {...singleItem}];
        count+=1;
      }
    })

    props.setReviewData([...reviewResult]);
    if (section == 'unanswerStatus') {
      let setFinalStr = false;
      const answeredQues = quizanswer.map((ans) => (ans.qid));
      const uniqueAnseweredQues = [...new Set(answeredQues)];
      
      if (uniqueAnseweredQues.length === qid.length) {
        setFinalStr = true;
      }
      setToFinal(setFinalStr ? '' : '<p><span style="color:#e74c3c">You have unanswered questions</span></p>')
      return
    }
    
    props.setResult(true);
    props.setTime(0);
    setSubmitLoading(true);
  };

  const setToFinal = (str) => {
    Swal.close();
    Swal.fire({
      title: 'Are you sure?',
      // text: Str.setFinalPopup,
      html: `${Str.setFinalPopup} ${str}`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: Str.Submit,

    }).then(function (result) {
      if (result.value) {
        handleSubmit('')
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "",
          timer: 1000,
          onOpen: function () {
            Swal.showLoading();
          },
        })
        // Swal.close()
      }
    }).finally(()=>{setSubmitLoading(false);});
  }

  const checkLocalstorageKeys = (item) => {
    let isZero = 0;
    if (Array.isArray(item)) {
      for (let i = 0; i < item.length; i++) {
        if (!localStorage.getItem(`@qa${item[i].qid}qa`) || localStorage.getItem(`@qa${item[i].qid}qa`)==null || localStorage.getItem(`@qa${item[i].qid}qa`) == 0) {
          isZero = 0;
        } else {
          isZero = 1;
          break;
        }
      }
    } else {
      isZero = (!localStorage.getItem(`@qa${item.qid}qa`) || localStorage.getItem(`@qa${item.qid}qa`)==null || localStorage.getItem(`@qa${item.qid}qa`) == 0) ? 0 : 1;
    }
    return isZero;
  }

  const isSelectedAnswer = (item) => {
    let isSelected = false;
    if (Array.isArray(item)) {
      for (let i = 0; i < item.length; i++) {
        // console.log("___________________ selected ______", item[i].selected);
        if (item[i]?.selected?.length > 0 && item[i]?.selected !== "" && item[i].selected !== undefined && item[i].selected !== null) {
          isSelected = true;
          break;
        } else {
          isSelected = false;
        }
      }
    } else {
      isSelected = (item?.selected?.length > 0 && item?.selected !== "" && item?.selected !== undefined && item?.selected !== null) ? true : false;
    }
    return isSelected;
  }

  return (
    <div className="card card-header-transparent">
      {Array.isArray(questionData) ? (
        <div className="card card-quiz card-header-dark">
          <div className="card-header">{questionData[0]?.group_name}</div>
          <div className="card-body">
            {questionData.map((q, i) => (
              <div key={i} className={(questionData.length - 1) === i ? "" : "mb-4"}>
                <DisplayQuestion
                  grpId={questionData[0].qid}
                  qInd={i}
                  question={q}
                  setquestion={setquestionData}
                  refresh={refresh}
                  setrefresh={setrefresh}
                  files={props.files}
                  setFiles={setFiles}
                  setPhotoFiles={setPhotoFiles}
                  setVideoFiles={setVideoFiles}
                  setDocFiles={setDocFiles}
                  photoFiles={photoFiles}
                  videoFiles={videoFiles}
                  docFiles={docFiles}
                  assessmentdetails={props.assessmentdetails}
                  assessementQuestionList={props.assessementQuestionList}
                  clear={clear}
                  showQuesType={renderQuesType(q?.question_type)}
                />
                {i !== questionData.length -1 ? <hr/> : null}
              </div>
            ))}
          </div>
        </div>
      ) :
        (<div className="card card-quiz card-header-dark">
          <div className="card-header">Question {quesno} {renderQuesType(questionData?.question_type)}</div>
          <div className="card-body">
            <DisplayQuestion
              question={questionData}
              setquestion={setquestionData}
              refresh={refresh}
              files={props.files}
              setFiles={setFiles}
              setPhotoFiles={setPhotoFiles}
              setVideoFiles={setVideoFiles}
              setDocFiles={setDocFiles}
              photoFiles={photoFiles}
              videoFiles={videoFiles}
              docFiles={docFiles}
              assessmentdetails={props.assessmentdetails}
              assessementQuestionList={props.assessementQuestionList}
              setrefresh={setrefresh}
              clear={clear}
            />
          </div>
        </div>)
      }
      <ul className="quiz-info">
        <li className="Unanswered-quiz">Unanswered</li>
        <li className="answered-quiz">Answered</li>
        <li className="non-visited">Not-visited</li>
      </ul>
      <div className="pagination-btn-group d-flex justify-content-between mt-4 mb-4">
        <ul className="quiz-pagination d-flex">
          <li
            className={"page-item action-pagination " + (quesno !== 1 ? '' : 'disabled')}
            onClick={() => { handleNav('first') }}
          >
            <span className="page-link pre-page-link">
              <i className="fas fa-angle-double-left"></i>
            </span>
          </li>
          <li
            onClick={() => { handleNav('back') }}
            className={"page-item action-pagination pre-step-link " + (quesno !== 1 ? '' : 'disabled')}>
            <span className="page-link" aria-label="Previous">
              <i className="fas fa-chevron-left"></i>
              <span className="sr-only"><i className="fas fa-angle-double-left"></i></span>
            </span>
          </li>

          {quizData.map((item, index) => {
            var cls = "btn btn-white-bordered ";
            if (isSelectedAnswer(item)) {
              cls += "btn-white-bordered-green";
            } else if (
              // parseInt(localStorage.getItem((Array.isArray(item) ? `@${item.map(i => i.qid).join()}` : `@${item.qid}`))) == 0
              checkLocalstorageKeys(item) === 0 && index !== 0 
            ) {
              cls += "btn-white-bordered-orange";
            } else if (
              // (Array.isArray(item) ? item.map(i => i.selected).join('') : item.selected) == "" &&
              // parseInt(localStorage.getItem((Array.isArray(item) ? `@${item.map(i => i.qid).join()}` : `@${item.qid}`))) != 0
              index === 0 || (!isSelectedAnswer(item) && checkLocalstorageKeys(item) !== 0)
            ) {
              cls += "btn-white-bordered-red";
            }
            cls = cls + ` ${quesno - 1 == index ? 'active' : ''} ${isShowing(index)}`
            return (
              <li
                key={index}
                className={cls}
                onClick={() => {
                  setquesno(index + 1);
                  localStorage.setItem(
                    `@qa${Array.isArray(item) ? item[0].qid : item.qid}qa`,
                    parseInt(localStorage.getItem(`@qa${(Array.isArray(item) ? item[0].qid : item.qid)}qa`) || 0) + 1
                  );
                  setquestion(Array.isArray(item) ? item[0].qid : item.qid);
                  handleQuestion(Array.isArray(item) ? item[0].qid : item.qid);
                }}>
                <span className="page-link">{index + 1}</span>
              </li>
            );
          })}
          <li
            className={"page-item action-pagination next-step-link" + (quesno === quizData.length ? ' disabled' : '')}
            onClick={() => { handleNav('next') }}
          >
            <span className="page-link" aria-label="Next">
              <i className="fas fa-chevron-right"></i>
              <span className="sr-only">Next</span>
            </span>
          </li>
          <li
            className={"page-item action-pagination" + (quesno === quizData.length ? ' disabled' : '')}
            onClick={() => { handleNav('last') }}
          >
            <span className="page-link next-page-link"><i className={"fas fa-angle-double-right"}></i></span>
          </li>
        </ul>

        <div className="quiz-footer-btns">
          {quizData.length !== quesno ? (
            <span
              onClick={() => {
                const val = keys.indexOf(`qa${question.toString()}qa`);
                localStorage.setItem(
                  `@${keys[val + 1]}`,
                  parseInt(localStorage.getItem(`@${keys[val + 1]}`) || 0) +
                  1
                );
                setquestion(keys[val + 1].replaceAll("qa",""));
                setquesno(val + 2);
                handleQuestion(keys[val + 1].replaceAll("qa",""));
              }}
              className="btn btn-white-bordered btn-primary"
            >
              Next
            </span>
          ) : <button
            onClick={() => {
              // setshowmodal(true);
              handleSubmit('unanswerStatus')
            }}
            className={"btn btn-primary check-btn "}
            disabled={submitLoading==true}
          >
            <i className={submitLoading==true ? "fas fa-cog fa-spin" : "fas fa-check"}></i>
            Set to Final
          </button>}
        </div>
      </div>
    </div>    
  )
}

export default React.memo(PreviewQuestionsExam)